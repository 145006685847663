import { defineStore } from 'pinia'
import { api } from '@/api/api.js';

export const useUserStore = defineStore({
  id: 'user-store',
  state: () => ({
    version: '',
    currentPage: '',
    pageHistory: ['/users'],
    transaction: {
      info: {}
    },
    currentUser: null,
    user: {
      info: {},
      transactions: {
        data: [],
        pagination: {
          pageOffset: 0,
          pageSize: 10,
          sortBy: 'CreatedAt',
          sortOrder: 'asc',
          totalPages: 0,
          total: 0,
          currentPage: 1
        }
      },
    }
  }),
  actions: {
    async loadVersion() {
      const { data } = await api.general.getEnvData()

      this.version = data?.version || ''
    },
    async getCurrentUser() {
      const { data } = await api.auth.getCurrentUser()

      this.currentUser = data
    },
    async getUserInfo(id) {
      try {
        const { data } = await api.users.getUserInfo(id)
        this.user.info = {
          ...data,
          merchantName: ''
        }

        return data
      } catch (error) {

      }
    },
    async getEmployeeInfo(id) {
      try {
        const { data } = await api.payoro.getEmployeeInfo(id)
        return data
      } catch (error) {
        throw error
      }
    },
    async updateUserData(userData, id) {
      try {
        const { data } = await api.users.updateUser(id, userData)
        return data
      } catch (error) {

      }
    },
    async createPayoroEmployee(employeeData) {
      try {
        const { data } = await api.payoro.createEmployee(employeeData)
        return data
      } catch (error) {
        throw error
      }
    },
    async updateEmployeeData(employeeData, id) {
      try {
        const { data } = await api.payoro.updateEmployee(id, {
          ...employeeData,
          id
        })
        return data
      } catch (error) {
        throw error
      }
    },
    async getTransactionInfo(id) {
      try {
        const { data } = await api.transactions.getTransactionInfo(id)
        this.transaction.info = data
        this.transaction.info.merchantName = ''
      } catch (error) {

      }
      try {
        const { data: merchantInfo } = await api.merchants.getMerchantInfo(this.transaction.info.merchantId)

        this.transaction.info.merchantName = merchantInfo.merchant.name
      } catch (error) {

      }
    },
    handleHistoryBack() {
      this.pageHistory.pop()
      this.pageHistory.pop()
    }
  }
})
